import { ActionTypes } from '../actions'

const {
  FLASH_MESSAGE_CHANGE_DATA,
  FLASH_MESSAGE_SET_MODAL_VISIBILITY,
  GET_AUDITS_STATUS,
  GET_AUDIT_ERROR_MESSAGES,
  GET_ROUND_TOURNAMENT_DATA,
  GGID_CHANGE,
  GGID_IS_VALID,
  GGID_TOGGLE_EDIT,
  GGID_UPDATE,
  HANDICAP_ANALYSIS_SEARCH,
  HANDICAP_ANALYSIS_SHOW_HIDDEN_DETAILS,
  SELECT_EMAILS_OR_TEXTS,
  SELECT_ROUND_TOURNAMENT,
  SET_ADJUST_HANDICAP_FORM_DATA,
  REPORT_PRINT_ID,
  REPORT_PRINT_FINISHED,
  REPORT_DOC_CONVERT_FINISHED,
  REPORT_TXT_CONVERT_FINISHED,
  REPORT_GENERATE_DOC,
  REPORT_GENERATE_TXT,
  REPORT_SET_STATE,
  REPORT_GET_LIST_CONTENT,
  REPORT_SELECT_LIST,
  REPORT_SEARCH,
  REPORT_IMPORT,
  REPORT_POLL_JOB_STATUS,
  REPORT_TOGGLE_PREVIEW_MODAL,
  CHECK_HOST_VENUE_STATUS,
  SET_ROUND_STATUS,
  TOGGLE_ADJUST_HANDICAP_MODAL,
  TOGGLE_ROUND_OPTIONS,
  WIDGETS_DISPLAY,
  WIDGETS_LOAD_CONTENT,
  WIDGETS_LOAD_ON_SUCCESS,
  SELECT_TOURNAMENT_TOGGLE_MODAL,
  WALKING_SCORER_STATION_TOGGLE_MODAL,
  LOAD_ROUND_TOURNAMENTS,
  SET_SELECTED_DEFAULT_ROUND_TOURNAMENT,
  TOGGLE_DIVISION_ACTIONS,
  TOGGLE_SCORING_LINKS,
  SET_GENERIC_DATA,
  TOGGLE_GOLFHUB_PAGE_PUBLISHED,
  TOGGLE_EVENT_REGISTRATION,
} = ActionTypes

const initialState = {}
let tmpLists = {}

const widgets = (state = initialState, action) => {
  switch (action.type) {
    case WIDGETS_DISPLAY:
      return {
        ...state,
        mobileVisibility: !state.mobileVisibility,
      }

    case WIDGETS_LOAD_ON_SUCCESS:
      return {
        ...state,
        ...action.data,
      }

    case WIDGETS_LOAD_CONTENT:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id) ? { ...widget, content: action.data } : widget))

      return state
    case SELECT_EMAILS_OR_TEXTS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id) ? { ...widget, content: { ...widget.content, selected: action.selected } } : widget))

      return state

    case SELECT_ROUND_TOURNAMENT:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id) ? { ...widget, content: { ...widget.content, selected: action.selected } } : widget))

      return state

    case GET_ROUND_TOURNAMENT_DATA:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              tablesData: {
                ...widget.content.tablesData,
                settingsTable: {
                  ...widget.content.tablesData.settingsTable,
                  data: {
                    ...widget.content.tablesData.settingsTable.data,
                    [action.selected]: action.tableContent,
                  },
                },
                dataTable: {
                  ...widget.content.tablesData.dataTable,
                  content: {
                    ...widget.content.tablesData.dataTable.content,
                    [action.selected]: action.tableData,
                  }},
              },
            },
          }
          : widget))

      return state

    case SET_ROUND_STATUS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id) ? { ...widget, content: { ...widget.content, roundProgress: action.status } } : widget))

      return state

    case CHECK_HOST_VENUE_STATUS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id) ? { ...widget, content: { ...widget.content, table: { ...widget.content.table, data: action.data } } } : widget))

      return state

    case TOGGLE_ROUND_OPTIONS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              roundOptions: {
                ...widget.content.roundOptions,
                [action.key]: {
                  ...widget.content.roundOptions[action.key],
                  status: !widget.content.roundOptions[action.key].status,
                },
              },
            },
          }
          : widget))

      return state

    case TOGGLE_SCORING_LINKS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              links: {
                ...widget.content.links,
                [action.key]: {
                  ...widget.content.links[action.key],
                  show: !widget.content.links[action.key].show,
                },
              },
            },
          }
          : widget))

      return state
    
    case GET_AUDITS_STATUS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              audits: Object.entries(widget.content.audits).reduce((newAudits, oldAudit) => { newAudits[oldAudit[0]] = { ...widget.content.audits[oldAudit[0]], status: action.status.filter(newStatus => newStatus[0] === oldAudit[0])[0][1] }; return newAudits }, {}),
            },
          }
          : widget))

      return state

    case GET_AUDIT_ERROR_MESSAGES:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              audits: Object.entries(widget.content.audits).reduce((newAudits, oldAudit) => {
                newAudits[oldAudit[0]] = oldAudit[0] === action.auditId
                  ? {
                    ...widget.content.audits[oldAudit[0]],
                    errors: {
                      ...widget.content.audits[oldAudit[0]].errors,
                      messages: action.messages,
                    },
                  }
                  : widget.content.audits[oldAudit[0]]
                return newAudits
              }, {}),
            },
          }
          : widget))

      return state

    case REPORT_SET_STATE:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? { ...list[1], report_center_items: list[1].report_center_items.map(report => report.id === action.reportId ? { ...report, state: action.status } : report) }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_GET_LIST_CONTENT:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: {
                ...widget.content.reportCenterLists,
                [action.selected]: {
                  ...widget.content.reportCenterLists[action.selected],
                  report_center_items: action.reports,
                },
              },
            },
          }
          : widget))

      return state

    case REPORT_SELECT_LIST:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              selectedList: action.selected,
            },
          }
          : widget))

      return state

    case REPORT_SEARCH:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              searchTerm: {
                ...widget.content.searchTerm,
                value: action.searchTerm,
              },
            },
          }
          : widget))

      return state

    case REPORT_IMPORT:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? { ...list[1], report_center_items: list[1].report_center_items.map(report => report.id === action.reportId ? { ...action.rci, state: widget.content.reportStates.hover} : report) }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_POLL_JOB_STATUS:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? {
                    ...list[1],
                    report_center_items: list[1].report_center_items.map(report =>
                      report.id === action.reportId
                        ? { ...report, job: { ...report.job, status: action.status } }
                        : report),
                  }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_PRINT_ID:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? { ...list[1], report_center_items: list[1].report_center_items.map(report => report.id === action.reportId ? { ...report, job: { id: action.jobId, type: 'pdf' }, state: widget.content.reportStates.processing } : report) }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_PRINT_FINISHED:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? {
                    ...list[1],
                    report_center_items: list[1].report_center_items.map(report =>
                      report.id === action.reportId
                        ? {
                          ...report,
                          job: {
                            ...report.job,
                            pdfPath: action.pdfPath,
                            pdfButtonLabel: action.pdfButtonLabel || 'Download PDF',
                            primaryDocxService: action.primaryDocxService,
                            generateTxt: action.generateTxt,
                          },
                          print_count: action.printCount,
                          state: widget.content.reportStates.download,
                        }
                        : report),
                  }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_GENERATE_DOC:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
            .map(list =>
              tmpLists[list[0]] = list[0] === widget.content.selectedList
                ? { ...list[1], report_center_items: list[1].report_center_items.map(report => report.id === action.reportId ? { ...report, job: { ...report.job, id: action.jobId, type: 'doc' }, state: widget.content.reportStates.processing } : report) }
                : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_GENERATE_TXT:
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
            .map(list =>
              tmpLists[list[0]] = list[0] === widget.content.selectedList
                ? { ...list[1], report_center_items: list[1].report_center_items.map(report => report.id === action.reportId ? { ...report, job: { ...report.job, id: action.jobId, type: 'txt' }, state: widget.content.reportStates.processing } : report) }
                : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_TOGGLE_PREVIEW_MODAL:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportPreviewModal: {
                visible: action.visible,
                imgSrc: action.imgSrc,
                reportName: action.reportName,
              },
            },
          }
          : widget))

      return state

    case SELECT_TOURNAMENT_TOGGLE_MODAL:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              selectTournamentModal: {
                ...widget.content.selectTournamentModal,
                show: action.visible,
              },
            },
          }
          : widget))

      return state

    case WALKING_SCORER_STATION_TOGGLE_MODAL:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              walkingScorerStationModal: {
                ...widget.content.walkingScorerStationModal,
                show: action.visible,
              },
            },
          }
          : widget))

      return state

    case LOAD_ROUND_TOURNAMENTS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              selectTournamentModal: {
                ...widget.content.selectTournamentModal,
                tournaments: action.tournaments,
              },
            },
          }
          : widget))

      return state

    case TOGGLE_DIVISION_ACTIONS:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              divisions: widget.content.divisions.map(division => ( { ...division, showActions: division.id === action.divisionId ? !division.showActions : false } ) ),
            },
          }
          : widget))

      return state

    case SET_SELECTED_DEFAULT_ROUND_TOURNAMENT:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              selectTournamentModal: {
                ...widget.content.selectTournamentModal,
                selected: action.selected,
              },
            },
          }
          : widget))

      return state

    case REPORT_DOC_CONVERT_FINISHED:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? {
                    ...list[1],
                    report_center_items: list[1].report_center_items.map(report =>
                      report.id === action.reportId
                        ? {
                          ...report,
                          job: {
                            ...report.job,
                            docxPath: action.docxPath,
                          },
                          state: widget.content.reportStates.download,
                        }
                        : report),
                  }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case REPORT_TXT_CONVERT_FINISHED:
      // eslint-disable-next-line no-case-declarations
      tmpLists = {}
      Object.entries(state).map(entry => 
        entry[1].map(widget => (widget.id === action.id)
          ? Object.entries(widget.content.reportCenterLists)
              .map(list =>
                tmpLists[list[0]] = list[0] === widget.content.selectedList
                  ? {
                    ...list[1],
                    report_center_items: list[1].report_center_items.map(report =>
                      report.id === action.reportId
                        ? {
                          ...report,
                          job: {
                            ...report.job,
                            txtPath: action.txtPath,
                          },
                          state: widget.content.reportStates.download,
                        }
                        : report),
                  }
                  : list[1])
          : null))

      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              reportCenterLists: tmpLists,
            },
          }
          : widget))

      return state

    case FLASH_MESSAGE_SET_MODAL_VISIBILITY:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              flashMessageModal: {
                ...widget.content.flashMessageModal,
                show: action.show,
              },
            },
          }
          : widget))

      return state

    case FLASH_MESSAGE_CHANGE_DATA:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              flashMessageModal: {
                ...widget.content.flashMessageModal,
                data: {
                  ...widget.content.flashMessageModal.data,
                  [action.key]: action.value,
                },
              },
            },
          }
          : widget))

      return state

    case GGID_CHANGE:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              ggid: {
                ...widget.content.ggid,
                new: action.newGgid,
              },
            },
          }
          : widget))

      return state

    case GGID_IS_VALID:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              ggid: {
                ...widget.content.ggid,
                valid: action.validity,
              },
            },
          }
          : widget))

      return state

    case GGID_TOGGLE_EDIT:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? (widget.content.ggid.edit
            ? {
              ...widget,
              content: {
                ...widget.content,
                ggid: {
                  ...widget.content.ggid,
                  edit: !widget.content.ggid.edit,
                  valid: true,
                  new: widget.content.ggid.value,
                },
              },
            }
            : {
              ...widget,
              content: {
                ...widget.content,
                ggid: {
                  ...widget.content.ggid,
                  edit: !widget.content.ggid.edit,
                },
              },
            })
          : widget))

      return state

    case GGID_UPDATE:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              ggid: {
                ...widget.content.ggid,
                edit: !widget.content.ggid.edit,
                valid: true,
                value: widget.content.ggid.new,
              },
            },
          }
          : widget))

      return state

    case HANDICAP_ANALYSIS_SHOW_HIDDEN_DETAILS:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              tablesData: {
                ...widget.content.tablesData,
                dataTable: {
                  ...widget.content.tablesData.dataTable,
                  hidden_details_to_show: (widget.content.tablesData.dataTable.hidden_details_to_show === action.showKey ? null : action.showKey),
                },
              },
            },
          }
          : widget))

      return state

    case HANDICAP_ANALYSIS_SEARCH:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              searchTerm: {
                ...widget.content.searchTerm,
                value: action.searchTerm,
              },
            },
          }
          : widget))

      return state

    case TOGGLE_ADJUST_HANDICAP_MODAL:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              adjustmentHandicapModal: {
                ...widget.content.adjustmentHandicapModal,
                show: action.show,
              },
            },
          }
          : widget))

      return state

    case SET_ADJUST_HANDICAP_FORM_DATA:
      Object.entries(state).map(entry =>
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              adjustmentHandicapModal: {
                ...widget.content.adjustmentHandicapModal,
                data: {
                  ...widget.content.adjustmentHandicapModal.data,
                  title: widget.content.tournaments.filter(tournament => tournament.id === widget.content.selected)[0].name,
                  golfer: action.golfer,
                  label: widget.content.tournaments.filter(tournament => tournament.id === widget.content.selected)[0].whs_enabled ? `Playing Handicap for ${action.golfer}` : `Course Handicap for ${action.golfer}`,
                  handicap: action.handicap,
                  player_ids: action.playerIds,
                  adjustment_path: action.ajustmentPath,
                  clear_adjustment_path: action.clearAdjustmentPath,
                },
              },
            },
          }
          : widget))


      return state

    case SET_GENERIC_DATA:
      return Object.keys(state).reduce((newState, key) => {
        newState[key] = state[key].map(widget => {
          if (widget.id !== action.id) { return widget }

          return {
            ...widget,
            content: objSetDeepProp(widget.content, action.keys, action.value),
          }
        })

        return newState
      }, {})
    
    case TOGGLE_GOLFHUB_PAGE_PUBLISHED:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              golfhubPage: {
                ...widget.content.golfhubPage,
                enabled: action.status,
              },
            },
          }
          : widget))
      
      return state
    
    case TOGGLE_EVENT_REGISTRATION:
      Object.entries(state).map(entry => 
        state[entry[0]] = entry[1].map(widget => (widget.id === action.id)
          ? {
            ...widget,
            content: {
              ...widget.content,
              golfhubPage: {
                ...widget.content.golfhubPage,
                registrationOpen: action.status,
              },
            },
          }
          : widget))
      return state
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

const objSetDeepProp = (obj, keys, value) => {
  if (keys.length === 0) { return value }

  obj = (obj === undefined) ?
    (Number.isInteger(keys[0]) ? [] : {}) :
    (Array.isArray(obj) ? [ ...obj ] : {...obj})
  obj[keys[0]] = objSetDeepProp(obj[keys[0]], keys.slice(1), value)
  return obj
}

export default widgets
