import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import teeTimeIcon from 'widgets/icons/tee_time.png'
import playerIcon1 from 'widgets/icons/player1.png'
import playerIcon2 from 'widgets/icons/player2.png'
import playerIcon3 from 'widgets/icons/player3.png'
import playerIcon4 from 'widgets/icons/player4.png'
import playerIcon5 from 'widgets/icons/player5.png'
import playerIcon6 from 'widgets/icons/player6.png'
import {useDispatch, useSelector} from 'react-redux'
import OttSlot from './ott_slot'
import holeIcon from 'widgets/icons/hole.png'
import OttScreen from '../ott_screen'
import {moveHere, selectAll, unselect} from '../actions'
import OttErrorPopup from './ott_error_popup'

const OttMobileLayout = () => {
  // Component State
  const [ isChecked, setChecked ] = useState(true)
  const dispatch = useDispatch()

  // Redux State
  const teeSheetData = useSelector(state => state.data )
  const checkedSlots = useSelector(state => state.checkedSlots )
  const dt = Object.values(teeSheetData)
  const state = useSelector(state => state )
  const activeScreen = useSelector(state => state.activeScreen)
  const playersCount = useSelector(state => state.playersCount)
  const useBayTerminology = useSelector(state => state.useBayTerminology)
  const [ isErrorPopupOpen, setErrorPopupOpen ] = useState(false)
  const [ errorPopupMessage, setErrorPopupMessage ] = useState('')

  // UseEffects
  useEffect(() => {
    // TODO: Check if the useEffect is needed.
  }, [ state ])

  // Business Logic
  const moveHereButton = (e, foursomeId) => {
    e.preventDefault()

    dispatch(moveHere(foursomeId, checkedSlots))
    setChecked(!isChecked)
  }

  const unselectButton = (e, foursomeId) => {
    e.preventDefault()

    dispatch(unselect(foursomeId))
  }

  const selectAllButton = (e, foursomeId) => {
    e.preventDefault()

    dispatch(selectAll(foursomeId))
  }

  const closeErrorPopup = () => {
    setErrorPopupOpen(false)
  }

  const handleExpiration = () => {
    setErrorPopupOpen(true)
    setErrorPopupMessage(window.I18n.t('players_choose_tee_times.ott_2_0.error.expired_reservation'))
  }

  const getIcon = ( position) => {
    switch (position) {
      case 0:
        return playerIcon1
      case 1:
        return playerIcon2
      case 2:
        return playerIcon3
      case 3:
        return playerIcon4
      case 4:
        return playerIcon5
      case 5:
        return playerIcon6
      default:
        return playerIcon1
    }
  }

  // Render
  return (
    <div>
      <div key={'mykey'}>
        {dt.map((row, index) => (
          <div key ={`tee_time_${index}`} className="grid-container">
            <div className="title">
              <div className="left-div">
                <div >{row.course}</div>
                <div className="hole-style"><img src={holeIcon}/>{row.hole ? (useBayTerminology ? window.I18n.t('players_choose_tee_times.ott_2_0.table.bay') : window.I18n.t('players_choose_tee_times.ott_2_0.table.hole')) + row.hole : (useBayTerminology ? window.I18n.t('players_choose_tee_times.ott_2_0.table.bay') : window.I18n.t('players_choose_tee_times.ott_2_0.table.hole')) + '-'}</div>
              </div>
              <div className="right-div"><img src={teeTimeIcon}/>{row.time}</div>
              {activeScreen === OttScreen.CHANGE_TEE_TIME && row.foursome_players.some(player => player.is_checked) && // edit mode and current foursome has checked players
                (<div className="edit-column">
                  {row.has_players_owned_by_members === true ? // if there are players owned by current member => Unselect all button
                    <div className={'edit-button-class'} onClick={(e) => unselectButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.unselect')} </div>
                    : ( row.foursome_players.some(player => player.is_valid_for_moving === true) === true ? // if there are spots valid for moving: Move here button
                      <button className={'edit-button-class'} onClick={(e) => moveHereButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here')} </button> : // otherwise, disabled move here
                      <div className={'disabled-color'} > {window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here')} </div> )} </div>)}
              {activeScreen === OttScreen.CHANGE_TEE_TIME && !(row.foursome_players.some(player => player.is_checked)) //  edit mode and current foursome has no checked players
                && (<div className="edit-column"> {
                  row.has_players_owned_by_members === true ? // if there are players owner by current member => Select all button
                    <div onClick={(e) => selectAllButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.select_all')} </div> :
                    checkedSlots.length !== 0 ? // if there are some players => move here button. else: nothing displayed
                      <button className={'edit-button-class'} onClick={(e) => moveHereButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.move_here')} </button> :
                      <div> </div>
                }
                </div>)}

              {activeScreen === OttScreen.REMOVE_PLAYERS && row.foursome_players.some(player => player.owned_by_current_member && player.player !== null) === true
                && (<div className="edit-column"> {
                  row.foursome_players.some(player => player.is_checked && player.owned_by_current_member) === true ?
                    <button className={'edit-button-class'} onClick={(e) => unselectButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.unselect')} </button> :
                    <button className={'edit-button-class'} onClick={(e) => selectAllButton(e, row.foursome_id)}> {window.I18n.t('players_choose_tee_times.ott_2_0.table.select_all')} </button>
                }

                </div>)}




            </div>
            {Array.from(Array(playersCount).keys()).map(position => (
              <div key={`slot_${index}_${position}`} className="grid-item">
                <div className="number">
                  <img src={getIcon( position)} alt="Player Icon" />
                </div>
                <div key={`${row.foursome_id}_${position}`} className={'content colored-box-style'}>
                  <OttSlot
                    position={position}
                    foursome_id={row.foursome_id}
                    player={row.foursome_players[position].player}
                    owned_by_current_member={row.foursome_players[position].owned_by_current_member}
                    is_reserved={row.foursome_players[position].is_reserved}
                    is_selected={row.foursome_players[position].is_selected}
                    is_valid_for_moving={row.foursome_players[position].is_valid_for_moving}
                    is_checked={row.foursome_players[position].is_checked}
                    reservation_seconds_left={row.foursome_players[position].reservation_seconds_left}
                    handleExpiration={handleExpiration}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {isErrorPopupOpen && <OttErrorPopup message={errorPopupMessage} handleClose={closeErrorPopup}/>}
    </div>
  )
}

// PropTypes
OttMobileLayout.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      column1: PropTypes.string.isRequired,
      column2: PropTypes.string.isRequired,
    })
  ).isRequired,
}
export default OttMobileLayout

